<template>
    <div class="h-screen w-[363px] relative" v-if="isShow">
        <div class="flex flex-col h-screen bg-white sidebar-inner overflow-scroll">
            <div
                    class="cursor-pointer flex justify-end mt-5 mr-5">
                    <div class="
                              flex flex-row
                              items-center
                              justify-center
                              transform
                              transition-transform
                              ease-in-out
                              duration-500">
                        <v-icon icon="icon-close-sidebar"
                        @click="hideSideBar"
                        id="icon-close-sidebar">
                        </v-icon>
                    </div>
                </div>
            <div class="flex flex-col items-center justify-center relative user-info-container">
                <div class="avatar-user rounded-full font-bold text-center uppercase">{{ store.state.user.username[0] || 'M' }}</div>
                <div class="font-bold text-center mt-4 name-user">{{ store.state.user.username }}</div>
            </div>
            <ul>
                <li v-for="(item, index) in list" :key="index"
                    class="py-5 px-14 cursor-pointer nab-container mb-[10px]"
                    :class="{'active': item.name.indexOf(currentNameRoute) > -1}">
                    <router-link
                        :to="item.to"
                        class="
                              flex flex-row
                              items-center
                              transform
                              transition-transform
                              ease-in-out
                              duration-500
                            "
                    >
                        <v-icon :icon="item.icon" :id="item.icon" v-if="item.name.indexOf(currentNameRoute) === -1"></v-icon>
                        <v-icon :icon="item.active_icon" :id="item.active_icon" v-if="item.name.indexOf(currentNameRoute) > -1"></v-icon>
                        <span class="font-normal ml-9">{{ item.title }}</span>
                    </router-link>
                </li>
            </ul>
            <div class="w-full logout-container mt-10">
                <ul>
                    <li class="py-5 px-14 cursor-pointer">
                        <router-link
                            to="/auth/logout"
                            class="
                              flex flex-row
                              items-center
                              transform
                              transition-transform
                              ease-in-out
                              duration-500
                            "
                        >
                            <v-icon icon="icon-logout" id="icon-logout"></v-icon>
                            <span class="font-normal ml-9">{{textDisplay.logoutTitle}}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="h-screen w-[134px] relative" v-else>
        <div class="flex flex-col h-screen bg-white sidebar-inner overflow-scroll">
            <div
                    class="cursor-pointer mt-5">
                    <div class="
                              flex flex-row
                              items-center
                              justify-center
                              transform
                              transition-transform
                              ease-in-out
                              duration-500">
                        <v-icon icon="icon-open-sidebar"
                        @click="showSideBar"
                        id="icon-open-sidebar">
                        </v-icon>
                    </div>
                </div>
            <div class="flex flex-col items-center justify-center relative user-info-container">
                <div class="avatar-user-closed rounded-full font-bold text-center uppercase">{{ store.state.user.username[0] || 'M' }}</div>
                <div class="font-bold text-center mt-4 name-user"></div>
            </div>
            <ul>
                <li v-for="(item, index) in list" :key="index"
                    class="py-5 cursor-pointer nab-container mb-[10px]"
                    :class="{'active': item.name.indexOf(currentNameRoute) > -1}">
                    <router-link
                        :to="item.to"
                        class="
                              flex flex-row
                              items-center
                              justify-center
                              transform
                              transition-transform
                              ease-in-out
                              duration-500
                            "
                    >
                        <v-icon :icon="item.icon" :id="item.icon" v-if="item.name.indexOf(currentNameRoute) === -1"></v-icon>
                        <v-icon :icon="item.active_icon" :id="item.active_icon" v-if="item.name.indexOf(currentNameRoute) > -1"></v-icon>
                    </router-link>
                </li>
            </ul>
            <div class="w-full logout-container mt-10">
                <ul>
                    <li class="py-5 cursor-pointer">
                        <router-link
                            to="/auth/logout"
                            class="
                              flex flex-row
                              items-center
                              justify-center
                              transform
                              transition-transform
                              ease-in-out
                              duration-500
                            "
                        >
                            <v-icon icon="icon-logout" id="icon-logout"></v-icon>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { textDisplay } from '../../common/TextDisplay'
import { useStore } from 'vuex'
import SVGIconComponent from '../common/SVGIconComponent'

/**
 * @module SidebarComponent
 */

export default {
    data() {
        return {
            isHoverUser: false,
            store: useStore(),
            textDisplay: textDisplay,
            isShow: localStorage.getItem('isSideBarShow') ? JSON.parse(localStorage.getItem('isSideBarShow')).isShow : true
        }
    },
    computed: {
        currentNameRoute() {
            return this.$route.name
        },
        list() {
            return this.store.state.user.authority === 1 ? this.textDisplay.sidebarList : this.textDisplay.sidebarListForGeneral
        }
    },
    methods: {

        /**
         * ログアウト画面へ
         */
        logout() {
            this.store.dispatch('logout').then((res) => {
                this.$router.push('/auth/login')
            })
        },

        /**
         * サイドバーを非表示にする
         */
        hideSideBar() {
            this.isShow = false
            localStorage.setItem('isSideBarShow', JSON.stringify({ isShow: false }))
        },

        /**
         * サイドバーを表示する
         */
        showSideBar() {
            this.isShow = true
            localStorage.setItem('isSideBarShow', JSON.stringify({ isShow: true }))
        }
    },
    components: {
        vIcon: SVGIconComponent
    }
}
</script>

<style scoped>
    .sidebar-inner {
        border-radius: 0px 50px 50px 0px;
    }
    .user-info-container {
        padding: 30px 0px 52px 0px;
    }
    .name-user {
        color: #1F2533;
    }
    .avatar-user {
        width: 88px;
        height: 88px;
        font-size: 45px;
        line-height: 88px;
        background-color: #E8F0FF;
        color: #015DB2;
    }
    .avatar-user-closed {
        width: 60px;
        height: 60px;
        font-size: 30px;
        line-height: 60px;
        background-color: #E8F0FF;
        color: #015DB2;
    }

    svg#icon-home,
    svg#icon-home-active,
    svg#icon-summary,
    svg#icon-summary-active,
    svg#icon-logout, svg#icon-search, svg#icon-search-active
    {
        width: 27px;
        height: 27px;
    }
    svg#icon-open-sidebar,
    svg#icon-close-sidebar{
        width: 40px;
        height: 40px;
    }

    svg#icon-user, svg#icon-user-active {
        width: 27px;
        height: 27px;
    }
    li span{
        color: #1F2533;
        font-size: 18px;
    }
    li.active {
        background-color: #E8F0FF;
        border-radius: 0px 50px 50px 0px;
    }
    li.active span {
        font-weight: bold;
        font-size: 20px;
    }
    .logout-container {
        bottom: 52px;
    }
    .logout-container li:hover {
        background-color: #E8F0FF;
        border-radius: 0px 50px 50px 0px;
    }
    .logout-container li:hover span {
        font-weight: bold;
        font-size: 20px;
    }
    .nab-container:hover {
        background-color: #E8F0FF;
        border-radius: 0px 50px 50px 0px;
    }
    .nab-container:hover span {
        font-weight: bold;
        font-size: 20px;
    }
</style>
