<template>
  <div class="flex justify-between">
    <SidebarComponent />
    <router-view />
  </div>
</template>

<script>
import SidebarComponent from '@/components/pages/SidebarComponent.vue'

export default {
    components: { SidebarComponent }
}
</script>

<style>

</style>
